<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <card class=" p-5">
          <h3 class="font-weight-bold mb-4" style='font-size: 30px'>Nueva Factura</h3>
          <div class="d-flex flex-row justify-content-between align-items-center" style="cursor: pointer;"
            @click="toggleCollapse(index)">
            <article>
              <h3 class="font-weight-bold mb-2" style='font-size: 20px'>Empresa</h3>
              <p class=" mb-2" style='font-size: 15px'>Cambie la dirección, el logotipo y otra
                información para su empresa.</p>
            </article>
            <h2 v-show="isCollapsed[index]"><i class="fa-solid fa-caret-down"></i></h2>
            <h2 v-show="!isCollapsed[index]"><i class="fa-solid fa-caret-up"></i></h2>
          </div>
          <hr style='height: 1px; background: var(--dark);' class='w-100 mb-3' />
          <transition name="fade">
            <div v-show="!isCollapsed[index]" class="pt-3 pb-5 row">
              <div class="col-12 col-md-4 py-2">
                <article class='mb-4'>
                  <label>Titulo</label>
                  <b-form-input id="input-live" v-model="valueNombre"
                    aria-describedby="input-live-help input-live-feedback" placeholder="Ingrese Titulo"
                    trim></b-form-input>
                </article>
                <article>
                  <label>Subtitulo</label>
                  <b-form-input id="input-live" v-model="valueNombre"
                    aria-describedby="input-live-help input-live-feedback" placeholder="Ingrese Subtitulo"
                    trim></b-form-input>
                </article>
              </div>
              <div class="col-12 col-md-4 py-2">
                <label>Logo</label>
                <div class="d-flex w-100 h-100">
                  <label for="dropzone-file"
                    class="custom-file-drop-area p-3 d-flex flex-column align-items-center justify-content-center cursor-pointer"
                    style="width: 12rem; height: 8rem">
                    <p class="fs-5 py-3 mb-0">Arrastre los archivos aquí pasa subirlos</p>
                    <input id="dropzone-file" type="file" hidden />
                  </label>
                </div>
              </div>
              <div class="col-12 col-md-4 py-2">
                <div class='d-flex align-items-center justify-content-between'>
                  <article class='d-flex flex-column justify-content-center' style='gap: .7rem;'>
                    <label class='mb-0'>Mi compañía</label>
                    <p class='mb-0'>my@company.com</p>
                  </article>
                  <vs-button icon><ion-icon name="pencil-sharp"></ion-icon></vs-button>
                </div>
              </div>
            </div>
          </transition>
          <div class='pt-3'>
            <h3 class="font-weight-bold mb-2" style='font-size: 20px'>Facturación</h3>
            <p class="mb-2" style='font-size: 13px'>Los datos de facturación aparecen en su factura. La fecha de la
              factura se utiliza en el panel de control e informes. Seleccione la fecha que usted espera que se le pague
              como fecha de vencimiento.</p>
          </div>
          <hr style='height: 1px; background: var(--dark);' class='w-100 mt-4' />
          <div class='row pt-3'>
            <div class="col-12 col-md-6 py-2">
              <label>selecione el cliente*</label><br />
              <b-form-select v-model="seleccionada" :options="listaClientes"></b-form-select>
              <div class='pt-2'>
                <label>Factura a</label>
                <h3>Abraham Hickle</h3>
                <p class="mb-1">46800 Kaley Crest Apt. 438 Port Daniella</p>
                <p class="mb-1">Mayotte</p>
                <p class="mb-1">CIF/NIF: 3166</p>
                <p class="mb-1">(831) 561-3786 - morris04@hotmail.com</p>
                <b-button variant="link">Editar Cliente</b-button>
              </div>
            </div>
            <div class="col-12 col-md-3 py-2">
              <article class='mb-4'>
                <label>Fecha de Factura *</label><br />
                <input placeholder="" type="date" class="form-control " />
              </article>
              <article>
                <label>Fecha de vencimiento *</label><br />
                <input placeholder="" type="date" class="form-control " />
              </article>
            </div>
            <div class="col-12 col-md-3 py-2">
              <article class='mb-4'>
                <label>Número de Factura *</label><br />
                <b-form-input id="input-live" v-model="valueNombre"
                  aria-describedby="input-live-help input-live-feedback" placeholder="Ingrese Nombre"
                  trim></b-form-input>
              </article>
              <article>
                <label>Nº Pedido</label><br />
                <b-form-input id="input-live" v-model="valueNombre"
                  aria-describedby="input-live-help input-live-feedback" placeholder="Ingrese Nombre"
                  trim></b-form-input>
              </article>
            </div>
          </div>
          <vs-table>
            <template #thead>
              <vs-tr>
                <vs-th>
                  Articulo
                </vs-th>
                <vs-th style='width: 280px !important;'>
                  Descripción
                </vs-th>
                <vs-th style='width: 180px !important;'>
                  cantidad
                </vs-th>
                <vs-th style='width: 180px !important;'>
                  Precio
                </vs-th>
                <vs-th style='width: 150px !important;'>
                  Impuesto %
                </vs-th>
                <vs-th style='width: 150px !important;'>
                  Valor
                </vs-th>
                <vs-th>
                  Opciones
                </vs-th>
              </vs-tr>
            </template>
            <template #tbody>
              <vs-tr v-for="(articulo, index) in articulos" :key="index">
                <vs-td>{{ articulo.nombre }}</vs-td>
                <vs-td>
                  <b-form-textarea rows="1" class='p-3' v-model="articulo.descripcion"
                    placeholder="Descripción"></b-form-textarea>
                </vs-td>
                <vs-td>
                  <b-form-input type="number" v-model="articulo.cantidad" min="1"></b-form-input>
                </vs-td>
                <vs-td>
                  <b-form-input type="number" v-model="articulo.precio" min="0" step="1"></b-form-input>
                </vs-td>
                <vs-td>
                  <b-form-input type="number" v-model="articulo.impuesto" min="0" max='100' step="1"></b-form-input>
                </vs-td>
                <vs-td>$ {{ calcularValor(articulo) }}</vs-td>
                <vs-td>
                  <vs-button class='bg-danger' @click="eliminarArticulo(index)">
                    <ion-icon name="trash-outline"></ion-icon>
                  </vs-button>
                </vs-td>
              </vs-tr>
            </template>
            <template #notFound>
              <vs-tr class='d-flex justify-content-center'>
                No hay Artículos
              </vs-tr>
            </template>
            <template #footer>
              <div v-if="!showSelect2" class='d-flex justify-content-center'>
                <vs-button dark shadow @click.prevent="toggleSelect2">Agregar Artículo +</vs-button>
              </div>
              <div v-if="showSelect2" class="d-flex justify-content-center mt-3">
                <b-form-select v-model="selectedItem" :options="options" @change="agregarArticulo"></b-form-select>
              </div>
            </template>
          </vs-table>
          <hr style='height: 1px; background: var(--dark);' class='w-100 mb-3' />
          <div class='d-flex flex-column justify-content-center align-items-end'>
            <article class='d-flex align-items-center mb-3' style='width: fit-content;gap: 30px;'>
              <h3>subTotal</h3>
              <p class='mb-0' style='width: 100px; font-size: 20px; text-align: end'>$ {{ calcularSubtotal() }}</p>
            </article>
            <article class='d-flex align-items-center mb-3' style='width: fit-content;gap: 30px;'>
              <h3 class='d-flex align-items-center flex-wrap' style="gap: 15px">Descuento <b-form-input
                  style='width: 63px;' v-model='descuento' type="number" min="0" max='100' step="1"></b-form-input> %
              </h3>
              <p class='mb-0' style='width: 100px; font-size: 20px; text-align: end'>$ {{ calcularSubtotal() *
                (descuento / 100)
                }}</p>
            </article>
            <article class='d-flex align-items-center' style='width: fit-content;gap: 30px;'>
              <h3>Total</h3>
              <p class='mb-0' style='width: 100px; font-size: 20px; text-align: end'>$ {{ descuento === 0 ?
                calcularSubtotal() :
                calcularSubtotal() - (calcularSubtotal() * (descuento / 100)) }}</p>
            </article>
          </div>
          <hr style='height: 1px; background: var(--dark);' class='w-100 mb-3' />
          <div class="d-flex flex-row justify-content-between align-items-center" style="cursor: pointer;"
            @click="toggleCollapse2(index)">
            <article>
              <h3 class="font-weight-bold mb-2" style='font-size: 20px'>Avanzado</h3>
              <p class=" mb-2" style='font-size: 13px'>Selecciona la categoría, añade o edita el pie de página y añade adjuntos a tu factura.</p>
            </article>
            <h2 v-show="isCollapsed2[index]"><i class="fa-solid fa-caret-down"></i></h2>
            <h2 v-show="!isCollapsed2[index]"><i class="fa-solid fa-caret-up"></i></h2>
          </div>
          <hr style='height: 1px; background: var(--dark);' class='w-100 mb-3' />
          <transition name="fade">
            <div v-show="!isCollapsed2[index]" class="pt-3 pb-5 row">
              <div class="col-12 col-md-6 py-2">
                <label>Pie de página</label>
                <b-form-textarea rows="6" class='p-3' placeholder="Ingrese pie de página"></b-form-textarea>
              </div>
              <div class="col-12 col-md-6 py-2">
                <article class='mb-3'>
                  <label>Categoria *</label>
                  <b-form-select :options="options"></b-form-select>
                </article>
                <article>
                  <label>Adjunto</label>
                  <div class="d-flex align-items-center justify-content-center w-100">
                    <label for="dropzone-file"
                      class="custom-file-drop-area d-flex flex-column align-items-center justify-content-center w-100 cursor-pointer">
                      <p class="fs-5 py-3 mb-0">Arrastre los archivos aquí pasa subirlos</p>
                      <input id="dropzone-file" type="file" hidden />
                    </label>
                  </div>
                </article>
              </div>
            </div>
          </transition>
          <div class='d-flex pt-4 justify-content-end'>
            <vs-button size="large" dark shadow @click.prevent="$router.push('Facturas')">Cancelar</vs-button>
            <vs-button size="large">Guardar</vs-button>
          </div>
        </card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  export default {
    data() {
      return {
        isCollapsed: {},
        isCollapsed2: {},
        showSelect: false,
        isDragging: false,
        showSelect2: false,
        descuento: 0,
        selectedItem: null,
        articulos: [],
        options: [
          { value: null, text: 'Seleccione un artículo' },
          { value: { nombre: 'Artículo 1', descripcion: 'Descripción 1', cantidad: 1, precio: 100, impuesto: 10 }, text: 'Artículo 1' },
          { value: { nombre: 'Artículo 2', descripcion: 'Descripción 2', cantidad: 2, precio: 200, impuesto: 20 }, text: 'Artículo 2' },
          { value: { nombre: 'Artículo 3', descripcion: 'Descripción 3', cantidad: 3, precio: 300, impuesto: 30 }, text: 'Artículo 3' }
        ]
      }
    },
    methods: {
      toggleCollapse(colapsar) {
        this.$set(this.isCollapsed, colapsar, !this.isCollapsed[colapsar]);
      },
      toggleCollapse2(colapsar) {
        this.$set(this.isCollapsed2, colapsar, !this.isCollapsed2[colapsar]);
      },
      toggleSelect() {
        this.showSelect = !this.showSelect;
      },
      toggleSelect2() {
        this.showSelect2 = !this.showSelect2;
      },
      agregarArticulo() {
        if (this.selectedItem) {
          this.articulos.push(this.selectedItem);
          this.showSelect2 = false;
          this.selectedItem = null;
        }
      },
      eliminarArticulo(index) {
        this.articulos.splice(index, 1);
      },
      calcularValor(articulo) {
        return articulo.impuesto === 0 ? (articulo.precio * articulo.cantidad) : (((articulo.precio * articulo.cantidad) * articulo.impuesto) / 100) + (articulo.precio * articulo.cantidad);
      },
      calcularSubtotal() {
        return this.articulos.reduce((subtotal, articulo) => {
          return subtotal + parseFloat(this.calcularValor(articulo));
        }, 0);
      }
    }
  }
</script>

<style scoped>
  .custom-file-drop-area {
    border: 2px dashed #cfcfcf;
    border-radius: 8px;
    text-align: center;
    color: #b0b0b0;
    background-color: transparent;
  }
  .vs-table__thead .vs-table__th {
    background: var(--body-bg) !important;
  }
  .custom-file-drop-area:hover {
    border-color: #999;
    color: #777;
  }
</style>